<template>
  <div class="forgot-password-container">
    <h1 id="forgot-password-title">
      Forgot Password
    </h1>
    <v-form @submit.prevent="submit()">
      <p class="input-label">Email <span>*</span></p>
      <v-text-field
        name="email"
        v-model="email"
        type="email"
        prepend-inner-icon="mail"
        outlined
        validate-on-blur
      ></v-text-field>
      <div class="btn-container">
        <div style="display: flex;">
          <!-- <span style="align-self: center;">&lt;</span> -->
          <router-link to="login" class="back-btn">
            <p id="back-btn">Back</p>
          </router-link>
        </div>
        <v-btn color="primary" type="submit">Submit</v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { ForgotPassword } from "@/api/auth/forgot-password";
import EventBus from "@/event-bus";
export default {
  data() {
    return {
      email: ""
    };
  },
  methods: {
    async submit() {
      await ForgotPassword({ email: this.email });
      EventBus.$emit("toast", {
        text: "Check your email",
        type: "success"
      });
      this.$router.push("/auth/login");
    }
  }
};
</script>

<style scoped>
.forgot-password-container {
  max-width: 650px;
  width: 95%;
  margin: 0 auto;
}
#forgot-password-title {
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 60px;
  color: var(--v-grey-base);
}
.input-label {
  font-size: 22px;
  color: var(--v-grey-base);
}
.input-label span {
  vertical-align: super;
  color: #ff0000;
}
.btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.back-btn {
  align-self: center;
  display: flex;
  font-size: 20px;
}
.back-btn p {
  margin: 0;
}
@media only screen and (max-width: 767px) {
  #forgot-password-title {
    margin-bottom: 10px;
    font-size: 22px !important;
  }
  .btn-container {
    margin-top: 0;
  }
  .input-label {
    font-size: 18px;
  }
  .forgot-password p {
    font-size: 18px;
  }
  ::v-deep .v-btn__content {
    font-size: 18px;
  }
  .login-btn {
    width: 120px;
    height: 50px !important;
    padding: 10px 40px;
    border-radius: 10px;
  }
}
</style>
